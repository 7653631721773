import React from 'react'

import Page from '../containers/Page'


// =============================================================================
const NotFoundPage = () => (
  <Page title="There's nothing here...">
    <p>
      There's plenty of great stuff on this site, but sadly not here.
    </p>
  </Page>
)

// -----------------------------------------------------------------------------
export default NotFoundPage
